@import "mixins";

html {
    background-color: var(--color-background);
    height: 100%;
    min-height: 100%;
    color: var(--color-regular);
    scroll-behavior: smooth;
}

body {
    margin: 0;
    padding: 20px 0 30px;

    display: flex;
    flex-direction: column;
    min-height: 100%;
    width: 100%;
}

* {
    box-sizing: border-box;
}

a,
a:hover,
a:focus,
a:active {
    color: inherit;

    cursor: pointer;
    text-decoration: none;
}

.row-wrapper {
    display: flex;
    align-items: center;
    flex-direction: column;
    width: 100%;
    padding-left: 32px;
    padding-right: 32px;
    position: relative;
    overflow: hidden;

    @include mobile {
        padding-left: 16px;
        padding-right: 16px;
    }
}

.row-inner {
    width: 100%;
    max-width: var(--max-width);
}

.title {
    font: var(--font-64-70-medium);
    color: var(--color-regular);

    @include tablet {
        font: var(--font-55-65-medium);
    }

    @include mobile {
        font: var(--font-36-43-medium);
        text-align: center;
    }
}
