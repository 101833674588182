@import "mixins";

.footer-wrapper {
    margin-top: 226px;
    @include mobile {
        margin-top: 50px;
    }
}

.footer {
    display: flex;
    align-items: center;
}
