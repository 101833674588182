@use "sass:math";

$input-height: 64px;
$placeholder-height: 24px;

.form {
    display: flex;
    flex-direction: column;
    max-width: 300px;
    max-width: 100%;
    max-width: 360px;
}

.field {
    display: flex;
    position: relative;

    + .field {
        margin-top: 24px;
    }

    .input {
        font: var(--font-20-24-medium);
        color: var(--color-regular);
        padding: 0;
        cursor: text;
        height: $input-height;
        border-radius: 12px;
        border: 1px solid var(--color-regular);
        padding: 30px 16px 10px;
        width: 100%;

        &:focus {
            outline: none;
        }

        &::-webkit-input-placeholder {
            opacity: 0;
        }

        + .label {
            pointer-events: none;
            position: absolute;
            top: 0;
            left: 16px;
            transition: 200ms;
            transform: translate3d(0, math.div($input-height - $placeholder-height, 2), 0);
            font: var(--font-20-24-medium);
            color: var(--color-secondary);
        }

        &:not(:placeholder-shown) + .label,
        &:focus + .label {
            transform: translate3d(0, 10px, 0) scale(0.75);
            transform-origin: center left;
            // font: var(--font-15-18-medium)
        }
    }

    .checkbox {
        cursor: pointer;
        margin-right: 8px;
        + .label {
            font: var(--font-14-20-medium);
            color: var(--color-regular);
            cursor: pointer;
        }
    }
}

.submit {
    width: 100%;
    margin-top: 28px;
}
