$size: 600px;

.blob {
    animation: morph 8s ease-in-out infinite, rotation 20s linear infinite;
    border-radius: 60% 40% 30% 70% / 60% 30% 70% 40%;
    height: $size;

    width: $size;
    border: 1px solid var(--color-regular);
    transform: rotate(0);
}

@keyframes morph {
    0% {
        border-radius: 60% 40% 30% 70% / 60% 30% 70% 40%;
    }

    50% {
        border-radius: 30% 60% 70% 40% / 50% 60% 30% 60%;
    }

    100% {
        border-radius: 60% 40% 30% 70% / 60% 30% 70% 40%;
    }
}

@keyframes rotation {
    from {
        transform: rotate(0);
    }
    to {
        transform: rotate(-360deg);
    }
}
