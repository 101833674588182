$mobile-width: 480px;
$tablet-width: 768px;
$desktop-width: 1150px;

@mixin tablet {
    @media only screen and (max-width: $desktop-width) {
        @content;
    }
}

@mixin mobile {
    @media only screen and (max-width: $tablet-width) {
        @content;
    }
}
