.button {
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: inset 0 0 0 1px var(--color-primary-dark);
    border: none;
    color: var(--color-white);
    cursor: pointer;

    &-primary {
        background: var(--color-primary);

        &:hover {
            background: #31ac79;
        }
        &:active {
            background: #289e6d;
        }
    }

    &.size {
        &-small {
            padding: 8px 16px;
            border-radius: 6px;
            font: var(--font-14-18-medium);
        }
        &-medium {
            padding: 20px 20px;
            border-radius: 12px;
            font: var(--font-20-24-medium);
        }
    }

    &,
    &:hover,
    &:active,
    &:focus {
        outline: none;
    }
}

.link {
    color: var(--color-primary);

    &:hover {
        color: var(--color-primary-dark);
    }
}
