:root {
    --color-regular: #111111;
    --color-primary: #53c394;
    --color-primary-dark: #31a575;
    --color-secondary: #666666;
    --color-gray: #e5e5e5;
    --color-gray-dark: #c9c9c9;
    --color-yellow: #ffc700;
    --color-purple: #a259ff;
    --color-green: #3dbc87;
    --color-red: #ff8577;
    --color-blue: #725bff;
    --color-white: #ffffff;
    --color-pink: #ffa0b7;

    --color-background: var(--color-white);

    --main-font-family: "TT Norms Pro";

    --font-14-18-medium: 400 14px/18px var(--main-font-family);
    --font-14-20-medium: 400 14px/20px var(--main-font-family);
    --font-14-25-medium: 400 14px/25px var(--main-font-family);
    --font-15-18-medium: 400 15px/18px var(--main-font-family);
    --font-19-24-medium: 400 19px/24px var(--main-font-family);
    --font-19-25-medium: 400 19px/25px var(--main-font-family);
    --font-24-24-medium: 400 24px/24px var(--main-font-family);
    --font-20-24-medium: 400 20px/24px var(--main-font-family);
    --font-30-36-medium: 600 30px/36px var(--main-font-family);
    --font-32-38-medium: 600 32px/38px var(--main-font-family);
    --font-45-54-medium: 600 45px/54px var(--main-font-family);
    --font-64-70-medium: 600 64px/70px var(--main-font-family);
    --font-64-77-medium: 600 64px/77px var(--main-font-family);

    --font-17-22-medium: 400 17px/22px var(--main-font-family); // tablet
    --font-26-30-medium: 600 26px/30px var(--main-font-family); // tablet
    --font-55-65-medium: 600 55px/65px var(--main-font-family); // tablet

    --font-36-43-medium: 600 36px/43px var(--main-font-family); // mobile
    --font-20-24-medium-2: 600 20px/24px var(--main-font-family);

    --max-width: 1290px;
}
