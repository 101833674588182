@import "mixins";

.header-wrapper {
    z-index: 10000;

    position: fixed;
    top: 0;
    left: 0;
    padding-top: 20px;
    padding-bottom: 20px;

    @include mobile {
        padding-top: 16px;
        padding-bottom: 16px;
    }

    &.header-sticky {
        background: var(--color-background);

        @supports ((-webkit-backdrop-filter: none) or (backdrop-filter: none)) {
            background: rgba(255, 255, 255, 0.8);
            -webkit-backdrop-filter: blur(16px);
            backdrop-filter: blur(16px);
        }
    }
}

.header {
    display: flex;
    align-items: center;
    justify-content: space-between;

    &__links {
        display: flex;
        align-items: center;

        @include mobile {
            display: none;
        }
    }

    &__link {
        font: var(--font-15-18-medium);
        color: var(--color-regular);
        margin: 0 8px;
        padding: 4px 8px;

        &:hover {
            color: var(--color-primary);
        }
    }

    &__logo_wrapper {
        height: 30px;
    }

    &__logo {
        max-height: 100%;
    }
}
