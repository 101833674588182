/* TT Norms Pro */

@font-face {
    font-display: block;
    font-family: "TT Norms Pro";
    font-style: normal;
    font-weight: 400;
    src: url("/assets/fonts/TTNormsPro-Normal.eot");
    src: url("/assets/fonts/TTNormsPro-Normal.eot?#iefix") format("embedded-opentype"),
        url("/assets/fonts/TTNormsPro-Normal.woff2") format("woff2"),
        url("/assets/fonts/TTNormsPro-Normal.woff") format("woff"),
        url("/assets/fonts/TTNormsPro-Normal.ttf") format("truetype");
}

@font-face {
    font-display: block;
    font-family: "TT Norms Pro";
    font-style: normal;
    font-weight: 500;
    src: url("/assets/fonts/TTNormsPro-Md.eot");
    src: url("/assets/fonts/TTNormsPro-Md.eot?#iefix") format("embedded-opentype"),
        url("/assets/fonts/TTNormsPro-Md.woff2") format("woff2"), url("/assets/fonts/TTNormsPro-Md.woff") format("woff"),
        url("/assets/fonts/TTNormsPro-Md.ttf") format("truetype");
}

@font-face {
    font-display: block;
    font-family: "TT Norms Pro";
    font-style: normal;
    font-weight: 600;
    src: url("/assets/fonts/TTNormsPro-DmBd.eot");
    src: url("/assets/fonts/TTNormsPro-DmBd.eot?#iefix") format("embedded-opentype"),
        url("/assets/fonts/TTNormsPro-DmBd.woff2") format("woff2"),
        url("/assets/fonts/TTNormsPro-DmBd.woff") format("woff"),
        url("/assets/fonts/TTNormsPro-DmBd.ttf") format("truetype");
}
