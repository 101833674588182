@import "mixins";

#dog {
    z-index: 10;
}
#leash {
    position: absolute;

    @include tablet {
        height: 230px;
    }
}
